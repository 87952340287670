
html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

.canvas {
  /* max-width: 100%; */
  // height: 100%;
  height: calc(100vh - 60px);
  // width: 100%;
  width: 100vw;
  background-color: var(--surface-500);
  position: relative;
}

@media screen and (min-width: 992px) {
  .canvas {
    width: calc(100vw - 451px);
  }
}

// .layout-sidebar {
//   height: auto;
//   max-height: calc(100vh - 7rem);
//   // padding: 1rem;
// }

// @media (max-width: 991px) {
//   .layout-wrapper .layout-sidebar {
//     top: 6rem;
//   }
// }

.new-element-selected {
  .joint-paper-scroller, .joint-element * {
    cursor: copy !important;
  }
}

.stretched-link::after{
  position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;pointer-events:auto;content:"";background-color:rgba(0,0,0,0)
}

.avatar-img {
  border-radius: 100%;
}

.mirror {
  transform: scale(-1, 1);
}

.p-button.p-button-xs {
  padding: 0.5rem;
  font-size: 0.875rem;
}

.bg-blue-500 {
  .p-button.p-button-outlined {
    color: var(--blue-800);
  }
  .p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: var(--blue-700);
  }
}

#app-sidebar {
  a {
    color: inherit;
  }
  .layout-menu li {
    a, .layout-menuitem-root-text {
      color: inherit;
    }
    a:hover {
      background-color: var(--indigo-600);
    }
    a.router-link-exact-active {
      background-color: var(--indigo-700);
    }
  }
  .p-tree {
    .p-tree-container .p-treenode {
      .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background-color: var(--indigo-600);
        color: inherit;
      }
      .p-treenode-content.p-highlight {
        color: inherit;
        background-color: var(--indigo-700);
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: var(--gray-50);
  }
}

.element-tree {
  .p-tree {
    background: inherit;
    color: inherit;
    .p-tree-container .p-treenode {
      padding-right: 0;
      padding-left: 0;
    }
    .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
      color: inherit;
    }
    .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
      color: inherit;
    }
  }



  .layout-menuitem-root-text {
    font-family: var(--font-family);
    user-select: none;
    list-style-type: none;
    box-sizing: border-box;
    text-transform: uppercase;
    color: var(--surface-900);
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  .p-tree {
    .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: var(--indigo-600);
    }
    .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
      // height: 1.5rem;
      // width: 1.5rem;
    }
    .p-tree-container .p-treenode .p-treenode-content {
      padding: 0.125rem 0.125rem 0.15rem 0.25rem;
    }
    border: none;
    padding: 0;
  }
}



.editable-cells-table td.p-cell-editing {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
