@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ps1ntf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ps1ntf') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ps1ntf##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="di-"], [class*=" di-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.di-uml-aggregation {
  &:before {
    content: $di-uml-aggregation; 
  }
}
.di-uml-composition {
  &:before {
    content: $di-uml-composition; 
  }
}
.di-uml-informationflow {
  &:before {
    content: $di-uml-informationflow; 
  }
}
.di-uml-class-dense {
  &:before {
    content: $di-uml-class-dense; 
  }
}
.di-uml-class {
  &:before {
    content: $di-uml-class; 
  }
}
.di-uml-realization {
  &:before {
    content: $di-uml-realization; 
  }
}
.di-uml-generalization {
  &:before {
    content: $di-uml-generalization; 
  }
}
.di-uml-extend {
  &:before {
    content: $di-uml-extend; 
  }
}
.di-uml-include {
  &:before {
    content: $di-uml-include; 
  }
}
.di-uml-dependency {
  &:before {
    content: $di-uml-dependency; 
  }
}
.di-uml-associate {
  &:before {
    content: $di-uml-associate; 
  }
}
.di-uml-assembly {
  &:before {
    content: $di-uml-assembly; 
  }
}
.di-uml-actor {
  &:before {
    content: $di-uml-actor; 
  }
}
.di-uml-usecase {
  &:before {
    content: $di-uml-usecase; 
  }
}
.di-uml-component {
  &:before {
    content: $di-uml-component; 
  }
}

