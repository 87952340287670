$icomoon-font-family: "dopredu-icons" !default;
$icomoon-font-path: "fonts" !default;

$di-uml-aggregation: "\e90a";
$di-uml-composition: "\e90b";
$di-uml-informationflow: "\e90e";
$di-uml-class-dense: "\e90c";
$di-uml-class: "\e90d";
$di-uml-realization: "\e909";
$di-uml-generalization: "\e906";
$di-uml-extend: "\e907";
$di-uml-include: "\e904";
$di-uml-dependency: "\e905";
$di-uml-associate: "\e902";
$di-uml-assembly: "\e903";
$di-uml-actor: "\e908";
$di-uml-usecase: "\e900";
$di-uml-component: "\e901";

